@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 100;
  src: local('Gotham Rounded Light'), url('../src/assets/fonts/Gotham-Rounded-Light.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham Rounded Medium'), url('../src/assets/fonts/Gotham-Rounded-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 700;
  src: local('Gotham Rounded Bold'), url('../src/assets/fonts/Gotham-Rounded-Bold.woff') format('woff');
}
